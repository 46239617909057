import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Card, Table, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChess, faDove, faDungeon } from '@fortawesome/free-solid-svg-icons';

const promoCodeStatsTablecolumns: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'name',
    header: 'Никнейм',
    cell: ({ row: { original } }) => {
      const { index, name } = original;
      return (
        <Link to={`/character/${index}`} target="_blank" className="fw-semi-bold line-clamp-1">
          {name}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'level',
    header: 'Уровень прав',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
];

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

const Police = () => {
  let [result, setResult] = useState<any[]>([]);
  const [name, setName] = useState<string>('');  
  const [data, setData] = useState<ProductsTableProductType[]>([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const table = useAdvanceTable({
    data: data,
    columns: promoCodeStatsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const addData = async (el) => {
    result.push(el);
    setResult(result);
  };

  const delData = async (name) => {
    result = result.filter((el) => el.a_nick != name);
    setResult(result);
  };

  const updateData = async (data) => {
    result.sort((a, b) => a.a_index - b.a_index);
    setResult(result);

    let array = [];

    for (let i = 0; i < result.length; i++) {
      let el = result[i];

      array.push({
        index: el.a_index,
        name: el.a_nick,
        level: el.a_level,
      });
    }

    setData(array);
  };

  const getApiData = async () => {
    setLoadingData(true);
    setData([]);

    let resultTemp = await $api.get('/character/police/list');

    result = [];
    setResult(result);

    for (let i = 0; i < resultTemp.data.length; i++) {
      addData(resultTemp.data[i]);
    }

    updateData();
    setLoadingData(false);
  };

  useEffect(() => {
    getApiData();
  }, []);

  const sendAdd = async () => {
    setLoadingData(true);

    try {
      let resultTemp = await $api.post('/character/police/add', { name });
      addData(resultTemp.data);
      updateData();
      toast.success(`Права успешно установлены.`);
    } catch (e) {
      toast.error(e.response?.data?.message);
    }

    setLoadingData(false);
  };

  const sendDel = async () => {
    setLoadingData(true);

    try {
      await $api.post('/character/police/del', { name });
      delData(name);
      updateData();
      toast.success(`Права успешно сняты.`);
    } catch (e) {
      toast.error(e.response?.data?.message);
    }

    setLoadingData(false);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Полиция</h2>
        <div className="mb-4"></div>
      </div>
      <div className="mx-lg-n4 mb-3">
        <Row className="g-3">
          <Col xs={12} xl={6} xxl={6}>
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap gap-3">
                  <Form.Control
                    type="string"
                    placeholder="Введите никнейм..."
                    value={name}
                    onChange={({ target }) => {
                      setName(target.value);
                    }}
                  />
                  <Button variant="primary" onClick={sendAdd} loading={isLoadingData} loadingPosition="start">Дать права</Button>
                  <Button variant="warning" onClick={sendDel} loading={isLoadingData} loadingPosition="start">Забрать права</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} xl={6} xxl={6}>
            <Card className="h-100">
              <Card.Body>
                <p className="text-700 mb-4"></p>
                  <AdvanceTableProvider {...table}>
                    <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                    <AdvanceTableFooter pagination />
                  </AdvanceTableProvider>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Police;
