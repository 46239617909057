import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Card, Table, Col, Row, Dropdown, Modal, Spinner, Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import Badge from 'components/base/Badge';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChess, faDove, faDungeon } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getPastDates } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { ThemeVariant } from 'config';
import { CallbackDataParams } from 'echarts/types/dist/shared';

echarts.use([TooltipComponent, BarChart]);

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export const tooltipFormatterList = (params: CallbackDataParams[]) => {
  const result = params.map((param, index) => {
    let label = '';
    if (dayjs(params[0].name).isValid()) {
      if (index > 0) {
        label = dayjs(dayjs(params[0].name).utc().format('YYYY-MM-DD HH:mm:ss')).subtract(1, 'month').format('MMM DD');
      } else {
        label = dayjs(dayjs(params[0].name).utc().format('YYYY-MM-DD HH:mm:ss')).format('MMM DD');
      }
    } else {
      label = params[0].name;
    }
    return {
      value: param.value,
      label,
      color: param.color
    };
  });

  let tooltipItem = ``;
  result.forEach((el, index: number) => {
    tooltipItem += `<h6 class="text-700 ${
      (result.length === 1 || index > 0) && 'mb-0'
    }"><span class="d-inline-block rounded-circle me-2" style="height: 0.5rem; width: 0.5rem; background:${
      el.color
    }"></span>
    ${el.label} : ${Number.isInteger(el.value) ? new Intl.NumberFormat().format(parseInt(el.value)) : el.value}
  </h6>`;
  });

  return `<div class='ms-1'>
            ${tooltipItem}
          </div>`;
};

const navItems: NavItemType[] = [
  {
    label: 'Полная',
    icon: faChartLine,
    eventKey: 'full'
  },
  {
    label: 'По дням',
    icon: faChartLine,
    eventKey: 'no_time'
  },
  {
    label: 'Сравнение',
    icon: faChartLine,
    eventKey: 'forever'
  },
  {
    label: 'Списком',
    icon: faChartLine,
    eventKey: 'history'
  },
];

function getShortValue(value: any) {
  let end = '';

  while (`${value}`.length > 3) {
    value = `${value}`.slice(0, -3);
    end += 'к';
  }

  return value + end;
}

// ГРАФИК РЕЗКИЕ ПЕРЕПАДЫ

const StatsNas = () => {
  let [dates, setDates] = useState<any[]>([]);
  let [currentMonthData, setCurrentMonthData] = useState<any[]>([]);
  let [prevMonthData, setPrevMonthData] = useState<any[]>([]);

  let [dates2, setDates2] = useState<any[]>([]);
  let [currentMonthData2, setCurrentMonthData2] = useState<any[]>([]);
  let [prevMonthData2, setPrevMonthData2] = useState<any[]>([]);

  const getDefaultOptions = (
    getThemeColor: (name: string) => string,
    theme: ThemeVariant
  ) => ({
    tooltip: {
      trigger: 'axis',
      padding: 10,
      backgroundColor: getThemeColor('gray-100'),
      borderColor: getThemeColor('gray-300'),
      textStyle: { color: getThemeColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      },
      formatter: tooltipFormatterList
    },
    xAxis: [
      /*{
        type: 'category',
        data: dates,
        axisLabel: {
          formatter: (value: string) => dayjs(dayjs(value).utc().format('YYYY-MM-DD HH:mm:ss')).format('DD MMM, YY'),
          interval: 3,
          showMinLabel: true,
          showMaxLabel: false,
          color: getThemeColor('gray-800'),
          align: 'left',
          fontFamily: 'Nunito Sans',
          fontWeight: 700,
          fontSize: 12.8,
          margin: 15
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: getThemeColor('gray-300')
          }
        },
        axisTick: {
          show: true,
          interval: 5
        },
        boundaryGap: false
      },*/
      {
        type: 'category',
        position: 'bottom',
        data: dates,
        axisLabel: {
          formatter: (value: string) => dayjs(dayjs(value).utc().format('YYYY-MM-DD HH:mm:ss')).format('DD.MM.YYYY'),
          interval: 130,
          showMaxLabel: true,
          showMinLabel: false,
          color: getThemeColor('gray-900'),
          align: 'right',
          fontFamily: 'Nunito Sans',
          fontWeight: 700,
          fontSize: 12.8,
          margin: 15
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: getThemeColor('gray-300')
          }
        },
        axisTick: {
          show: true
        },
        splitLine: {
          show: false
        },
        boundaryGap: false
      }
    ],
    yAxis: {
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        show: true,
        lineStyle: {
          color:
            theme === 'dark'
              ? getThemeColor('gray-100')
              : getThemeColor('gray-200')
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        fontFamily: 'Nunito Sans',
        fontWeight: 700,
        fontSize: 12.8,
        color: getThemeColor('gray-900'),
        margin: 25,
        // verticalAlign: 'bottom',
        formatter: (value: number) => getShortValue(value)
      }
      // axisLabel: { show: true }
    },
    series: [
      /*{
        name: 'e',
        type: 'line',
        data: prevMonthData,
        // symbol: 'none',
        lineStyle: {
          type: 'line',
          width: 3,
          color: getThemeColor('info-200')
        },
        showSymbol: false,
        symbol: 'emptyCircle',
        symbolSize: 6,
        itemStyle: {
          color: getThemeColor('info-200'),
          borderWidth: 3
        }
      },*/
      {
        name: 'd',
        type: 'line',
        data: currentMonthData,
        showSymbol: false,
        symbol: 'emptyCircle',
        symbolSize: 6,
        itemStyle: {
          color: getThemeColor('primary'),
          borderWidth: 3
        },

        lineStyle: {
          type: 'line',
          width: 3,
          color: getThemeColor('primary')
        }
      }
    ],
    grid: {
      right: 2,
      left: 5,
      bottom: '10px',
      top: '2%',
      containLabel: true
    },
    animation: false
  });

  const getDefaultOptions2 = (
    getThemeColor: (name: string) => string,
    theme: ThemeVariant
  ) => ({
    tooltip: {
      trigger: 'axis',
      padding: 10,
      backgroundColor: getThemeColor('gray-100'),
      borderColor: getThemeColor('gray-300'),
      textStyle: { color: getThemeColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      },
      formatter: tooltipFormatterList
    },
    xAxis: [
      {
        type: 'category',
        data: dates2,
        axisLabel: {
          formatter: (value: string) => dayjs(dayjs(value).utc().format('YYYY-MM-DD HH:mm:ss')).format('DD MMM, YY'),
          interval: 3,
          showMinLabel: true,
          showMaxLabel: false,
          color: getThemeColor('gray-800'),
          align: 'left',
          fontFamily: 'Nunito Sans',
          fontWeight: 700,
          fontSize: 12.8,
          margin: 15
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: getThemeColor('gray-300')
          }
        },
        axisTick: {
          show: true,
          interval: 5
        },
        boundaryGap: false
      },
      {
        type: 'category',
        position: 'bottom',
        data: dates2,
        axisLabel: {
          formatter: (value: string) => dayjs(dayjs(value).utc().format('YYYY-MM-DD HH:mm:ss')).format('DD MMM, YY'),
          interval: 130,
          showMaxLabel: true,
          showMinLabel: false,
          color: getThemeColor('gray-900'),
          align: 'right',
          fontFamily: 'Nunito Sans',
          fontWeight: 700,
          fontSize: 12.8,
          margin: 15
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: getThemeColor('gray-300')
          }
        },
        axisTick: {
          show: true
        },
        splitLine: {
          show: false
        },
        boundaryGap: false
      }
    ],
    yAxis: {
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        show: true,
        lineStyle: {
          color:
            theme === 'dark'
              ? getThemeColor('gray-100')
              : getThemeColor('gray-200')
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        fontFamily: 'Nunito Sans',
        fontWeight: 700,
        fontSize: 12.8,
        color: getThemeColor('gray-900'),
        margin: 25,
        // verticalAlign: 'bottom',
        formatter: (value: number) => getShortValue(value)
      }
      // axisLabel: { show: true }
    },
    series: [
      /*{
        name: 'e',
        type: 'line',
        data: prevMonthData2,
        // symbol: 'none',
        lineStyle: {
          type: 'line',
          width: 3,
          color: getThemeColor('info-200')
        },
        showSymbol: false,
        symbol: 'emptyCircle',
        symbolSize: 6,
        itemStyle: {
          color: getThemeColor('info-200'),
          borderWidth: 3
        }
      },*/
      {
        name: 'd',
        type: 'line',
        data: currentMonthData2,
        showSymbol: false,
        symbol: 'emptyCircle',
        symbolSize: 6,
        itemStyle: {
          color: getThemeColor('primary'),
          borderWidth: 3
        },

        lineStyle: {
          type: 'line',
          width: 3,
          color: getThemeColor('primary')
        }
      }
    ],
    grid: {
      right: 2,
      left: 5,
      bottom: '10px',
      top: '2%',
      containLabel: true
    },
    animation: false
  });

  const AdClicksChart = ({ style }: { style: CSSProperties }) => {
    const {
      config: { theme },
      getThemeColor
    } = useAppContext();

    return (
      <ReactEChartsCore
        echarts={echarts}
        option={getDefaultOptions(getThemeColor, theme)}
        style={style}
      />
    );
  };

  const AdClicksChart2 = ({ style }: { style: CSSProperties }) => {
    const {
      config: { theme },
      getThemeColor
    } = useAppContext();

    return (
      <ReactEChartsCore
        echarts={echarts}
        option={getDefaultOptions2(getThemeColor, theme)}
        style={style}
      />
    );
  };









  const getApiData = async () => {
    let result = await $api.get('/stat/nas/list');
    result = result.data;

    let arrayDates = [];
    let arrayCurrentMonthData = [];
    let arrayPrevMonthData = [];

    let arrayUseDay2 = [];
    let arrayDates2 = [];
    let arrayCurrentMonthData2 = [];
    let arrayPrevMonthData2 = [];

    for (let i = 0; i < result.length; i++) {
      let date = dayjs(dayjs(result[i].a_date).utc().format('YYYY-MM-DD HH:mm:ss'));

      arrayDates.push(result[i].a_date);
      arrayCurrentMonthData.push(result[i].a_total_sum);
      arrayPrevMonthData.push(0);

      if (!arrayUseDay2.includes(date.format('YYYY-MM-DD'))) {
        arrayUseDay2.push(date.format('YYYY-MM-DD'));
        arrayDates2.push(result[i].a_date);
        arrayCurrentMonthData2.push(result[i].a_total_sum);
        arrayPrevMonthData2.push(0);
      }
    }

    //const date1 = dayjs(dayjs(result[0].a_date).utc().format('YYYY-MM-DD HH:mm:ss'));
    //const date2 = dayjs(dayjs(result[result.length - 1].a_date).utc().format('YYYY-MM-DD HH:mm:ss'));

    //const date1 = dayjs(result[0].a_date).utc();
    //const date2 = dayjs(result[result.length - 1].a_date).utc();
    //setDates(getPastDates(result.length/*date1.diff(date2, 'day')*/));

    setDates(arrayDates);
    setCurrentMonthData(arrayCurrentMonthData);
    setPrevMonthData(arrayPrevMonthData);

    setDates2(arrayDates2);
    setCurrentMonthData2(arrayCurrentMonthData2);
    setPrevMonthData2(arrayPrevMonthData2);

    //console.log(date1);
    //console.log(date2);
  };

  useEffect(() => {
    getApiData();


    /*setDates(getPastDates(13));

    setCurrentMonthData([
      1000, 2250, 1070, 1200, 1000, 1450, 3100, 2900, 1800, 1450, 1700, 1700, 1700
    ]);

    setPrevMonthData([
      5000, 1200, 2700, 1700, 2100, 2000, 2300, 1200, 2600, 2900, 1900, 1700, 1700
    ]);*/


  }, []);

  const [cookies, setCookie] = useCookies(['mainTab']);

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Статистика золота</h2>
        <div className="mb-4"></div>
      </div>
      <Tab.Container defaultActiveKey={cookies.mainTab ? cookies.mainTab : "full"} onSelect=
          {(eventKey: string | null) => {
            if (eventKey) {
              setCookie('mainTab', eventKey);
            }
          }}>
          <Nav
            variant="underline"
            className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
          >
          {navItems.map((item, index) => (
            <Nav.Item key={item.eventKey} className="me-2 text-nowrap">
              <Nav.Link eventKey={item.eventKey.toLowerCase()}>
                <FontAwesomeIcon
                  icon={item.icon}
                  className="me-2 tab-icon-color"
                />
                {item.label}
              </Nav.Link>
            </Nav.Item>
          ))}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="full">


            <AdClicksChart style={{ height: '385px', width: '100%' }} />




            </Tab.Pane>
            <Tab.Pane eventKey="no_time">


                <AdClicksChart2 style={{ height: '385px', width: '100%' }} />



            </Tab.Pane>
            <Tab.Pane eventKey="forever">
              
            </Tab.Pane>
            <Tab.Pane eventKey="history">
              
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
    </div>
  );
};

export default StatsNas;
