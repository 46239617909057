import AppProvider from 'providers/AppProvider';
import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import Store from "./store/store";

interface State {
    store: Store,
}

export const store = new Store();

export const Context = createContext<State>({
    store,
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Context.Provider value={{
        store
    }}>
      <React.StrictMode>
        <AppProvider>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <RouterProvider router={router} />
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </AppProvider>
      </React.StrictMode>
  </Context.Provider>,
);
