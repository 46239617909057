import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Card, Table, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChess, faDove, faDungeon } from '@fortawesome/free-solid-svg-icons';

export interface ActivityTimelineData {
  id: number;
  date: string;
  comment: string;
  hash: string;
  separator: {
    icon: IconProp;
  };
}

import Timeline from 'components/base/Timeline';

interface ActivityTimelineProps {
  data: ActivityTimelineData[];
}

const ActivityTimeline = ({ data }: ActivityTimelineProps) => {
  return (
    <Timeline variant="vertical">
      {data.map((item, index) => (
        <Timeline.Item className="position-relative" key={item.id}>
          <Row className="g-md-3">
            <Col xs={12} md="auto" className="d-flex">
              <Timeline.OppositeContent>
                <p className="fs-10 fw-semi-bold text-600 text-end">
                  {item.date}
                </p>
              </Timeline.OppositeContent>
              <Timeline.Separator className="position-md-relative">
                <Timeline.Dot className="bg-primary-100">
                  <FontAwesomeIcon
                    icon={item.separator.icon}
                    className="text-primary-600 fs-10 dark__text-primary-300"
                  />
                </Timeline.Dot>
                {index !== data.length - 1 && (
                  <Timeline.Bar className="h-100 border-dashed border-400" />
                )}
              </Timeline.Separator>
            </Col>
            <Col>
              <Timeline.Content>
                {item.comment != '' && (
                  <h5 className="fs-9 lh-sm">
                    {item.comment}
                  </h5>
                )}
                <p className={classNames('fs-9 text-800', {
                    'mb-5': index !== data.length - 1,
                    'mb-0': index === data.length - 1
                  })}
                >
                  {item.hash}
                </p>
              </Timeline.Content>
            </Col>
          </Row>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

const CashShop = () => {
  let [result, setResult] = useState<any[]>([]);
  const [hash, setHash] = useState<string>('');  
  const [data, setData] = useState<ActivityTimelineData[]>([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const addData = async (el) => {
    el.a_date = dayjs(el.a_date).utc();
    result.unshift(el);
    setResult(result);
  };

  const delData = async (hash) => {
    result = result.filter((el) => el.a_hash != hash);
    setResult(result);
  };

  const updateData = async (data) => {
    let array = [];

    for (let i = 0; i < result.length; i++) {
      let el = result[i];
      el.a_date = dayjs(el.a_date).utc();

      array.push({
        id: i,
        comment: el.a_comment,
        date: el.a_date.format('DD.MM.YYYY HH:mm'),
        hash: el.a_hash,
        separator: {
          icon: faDove
        }
      });
    }

    setData(array);
  };

  const getApiData = async () => {
    setLoadingData(true);
    setData([]);

    let resultTemp = await $api.get('/const/hash/list');

    result = [];
    setResult(result);

    for (let i = 0; i < resultTemp.data.length; i++) {
      addData(resultTemp.data[i]);
    }

    updateData();
    setLoadingData(false);
  };

  useEffect(() => {
    getApiData();
  }, []);

  const sendAddHash = async () => {
    setLoadingData(true);

    try {
      let resultTemp = await $api.post('/const/hash/add', { hash });
      addData(resultTemp.data);
      updateData();
      toast.success(`Хеш успешно добавлен.`);
    } catch (e) {
      toast.error(e.response?.data?.message);
    }

    setLoadingData(false);
  };

  const sendDelHash = async () => {
    setLoadingData(true);

    try {
      await $api.post('/const/hash/del', { hash });
      delData(hash);
      updateData();
      toast.success(`Хеш успешно удалён.`);
    } catch (e) {
      toast.error(e.response?.data?.message);
    }

    setLoadingData(false);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Хеши</h2>
        <div className="mb-4"></div>
      </div>
      <div className="mx-lg-n4 mb-3">
        <Row className="g-3">
          <Col xs={12} xl={6} xxl={6}>
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap gap-3">
                  <Form.Control
                    type="string"
                    placeholder="Введите хеш..."
                    value={hash}
                    onChange={({ target }) => {
                      setHash(target.value);
                    }}
                  />
                  <Button variant="primary" onClick={sendAddHash} loading={isLoadingData} loadingPosition="start">Добавить</Button>
                  <Button variant="warning" onClick={sendDelHash} loading={isLoadingData} loadingPosition="start">Удалить</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} xl={6} xxl={6}>
            <Card className="h-100">
              <Card.Body>
                <p className="text-700 mb-4"></p>
                <ActivityTimeline data={data} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CashShop;
