import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, {FC, useContext, useState} from 'react';
import {Context} from "index";
import {observer} from "mobx-react-lite";

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const {store} = useContext(Context);

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Авторизация</h3>
      </div>      
      <Form.Group className="mb-3 text-start">
        <div className="form-icon-container">
          <Form.Control
            id="text"
            type="text"
            className="form-icon-input"
            placeholder="Логин"
            onChange={e => setEmail(e.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Пароль"
            onChange={e => setPassword(e.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Button variant="primary" className="w-100 mb-3" onClick={() => store.login(email, password)}>
        Продолжить
      </Button>
    </>
  );
};

export default observer(SignInForm);
