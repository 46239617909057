import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Card, Table, Col, Row, Dropdown, Modal, Spinner, Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import Badge from 'components/base/Badge';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChess, faDove, faDungeon } from '@fortawesome/free-solid-svg-icons';

const promoCodeStatsTablecolumns: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'login',
    header: 'Логин',
    cell: ({ row: { original } }) => {
      const { index, login } = original;
      return (
        <Link to={`/user/${index}`} target="_blank" className="text-decoration-none">
          {login}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'characters',
    header: 'Персонажи',
    cell: ({ row: { original } }) => {
      const { characters } = original;
      return (
        <div className="d-flex flex-wrap gap-2">
          {characters.map((el, index) => (
            <Link key={el.index} to={`/character/${el.index}`} target="_blank" className="text-decoration-none">
              <Badge variant="tag">{el.name}</Badge>
            </Link>
          ))}
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'time',
    header: 'Время',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'count',
    header: 'Блок. / Разблок.',
    cell: ({ row: { original } }) => {
      const { banCount, unbanCount } = original;
      return `${banCount} / ${unbanCount}`;
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'date_start',
    header: 'Дата начала',
    cell: ({ row: { original } }) => {
      const { date_start } = original;
      return `${date_start != '' ? dayjs(date_start).utc().format('DD.MM.YYYY HH:mm') : ''}`;
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'date_end',
    header: 'Дата окончания',
    cell: ({ row: { original } }) => {
      const { date_start, date_end_day } = original;
      return `${date_start != '' && date_end_day >= 0 ? dayjs(date_start).add(date_end_day, 'day').utc().format('DD.MM.YYYY HH:mm') : ''}`;
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  }, 
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      const { login, callback } = original;
      return (
        <Button variant="primary" onClick={() => callback(login)}>Разблокировать</Button>
      );
    },
    meta: {
      headerProps: { style: { width: 60 } },
      cellProps: { className: 'text-end' }
    }
  }
];

const promoCodeStatsTablecolumns4: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'login',
    header: 'Логин',
    cell: ({ row: { original } }) => {
      const { index, login } = original;
      return (
        <Link to={`/user/${index}`} target="_blank" className="text-decoration-none">
          {login}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'characters',
    header: 'Персонажи',
    cell: ({ row: { original } }) => {
      const { characters } = original;
      return (
        <div className="d-flex flex-wrap gap-2">
          {characters.map((el, index) => (
            <Link key={el.index} to={`/character/${el.index}`} target="_blank" className="text-decoration-none">
              <Badge variant="tag">{el.name}</Badge>
            </Link>
          ))}
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'type',
    header: 'Тип',
    cell: ({ row: { original } }) => {
      const { type } = original;

      const info = () => {
        switch (type) {
          case 0: return 'Заблокирован';
          case 1: return 'Разблокирован';
          case 2: return 'Разблокирован (авто.)';
          default: return 'Неизвестно';
        }
      }

      return (
        <div>{ info() }</div>
      )
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { date } = original;
      return dayjs(date).utc().format('DD.MM.YYYY HH:mm');
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'day',
    header: 'Количество дней',
    cell: ({ row: { original } }) => {
      const { date, day } = original;
      return (
        <>
          {day > 0 && (
            `${day} (${dayjs(date).add(day, 'day').utc().format('DD.MM.YYYY HH:mm')})`
          )}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  }
];

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)





const navItems: NavItemType[] = [
  {
    label: 'Временные',
    icon: faChartLine,
    eventKey: 'temp'
  },
  {
    label: 'Без времени',
    icon: faChartLine,
    eventKey: 'no_time'
  },
  {
    label: 'Навсегда',
    icon: faChartLine,
    eventKey: 'forever'
  },
  {
    label: 'История',
    icon: faChartLine,
    eventKey: 'history'
  },
];

function getTimeString(time) {
    if (time == 100) {
        return 'Навсегда.';
    }
    else if (time > 100) {
        return `${time - 100} д.`;
    }
    else if (time == 3 || time == -2) {
        return 'Без времени (взлом).';
    }
    else if (time == 4 || time == -3) {
        return 'Без времени (мошенник).';
    }
    else {
        return 'Без времени.';
    }
}

const Police = () => {
  const [login, setLogin] = useState<string>(''); 
  const [name, setName] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [data, setData] = useState<ProductsTableProductType[]>([]);
  const [data2, setData2] = useState<ProductsTableProductType[]>([]);
  const [data3, setData3] = useState<ProductsTableProductType[]>([]);
  const [data4, setData4] = useState<ProductsTableProductType[]>([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const table = useAdvanceTable({
    data: data,
    columns: promoCodeStatsTablecolumns,
    pageSize: 50,
    pagination: true,
    sortable: true
  });

  const table2 = useAdvanceTable({
    data: data2,
    columns: promoCodeStatsTablecolumns,
    pageSize: 50,
    pagination: true,
    sortable: true
  });

  const table3 = useAdvanceTable({
    data: data3,
    columns: promoCodeStatsTablecolumns,
    pageSize: 50,
    pagination: true,
    sortable: true
  });

  const table4 = useAdvanceTable({
    data: data4,
    columns: promoCodeStatsTablecolumns4,
    pageSize: 50,
    pagination: true,
    sortable: true
  });

  const getApiData = async () => {
    setLoadingData(true);
    setData([]);

    let result = await $api.get('/user/ban/list');
    result = result.data;

    for (let i = 0; i < result.list_default.length; i++) {
      result.list_default[i].type = 0;
    }

    for (let i = 0; i < result.list_notime.length; i++) {
      result.list_notime[i].type = 1;
    }

    for (let i = 0; i < result.list_unlimit.length; i++) {
      result.list_unlimit[i].type = 2;
    }

    let array = [];
    let array2 = [];
    let array3 = [];
    let array4 = [];

    let arrayMix = [...result.list_default, ...result.list_notime, ...result.list_unlimit];

    for (let i = 0; i < arrayMix.length; i++) {
      let el = arrayMix[i];
      el.callback = sendDel;
      let data = el;/*{
        index: el.index,
        login: el.login,
        characters: el.characters,
        time: el.time,
        banCount: el.banCount,
        unbanCount: el.unbanCount,
        date_start: el.date_start,
        date_end_day: el.date_end_day,
        comments: el.comments,
      };*/

      if (el.type == 0) {
        array.push(data);
      }
      else if (el.type == 1) {
        array2.push(data);
      }
      else if (el.type == 2) {
        array3.push(data);
      }
    }

    for (let i = 0; i < result.history_ban.length; i++) {
      let el = result.history_ban[i];

      array4.push(el/*{
        index: 0,
        login: el.a_login,
        date_start: el.a_date,
        date_end_day: el.date_end_day,
        comments: el.comment,
      }*/);
    }

    setData(array);
    setData2(array2);
    setData3(array3);
    setData4(array4);

    setLoadingData(false);
  };

  useEffect(() => {
    getApiData();
  }, []);

  const sendAdd = async () => {
    setLoadingData(true);

    try {
      let result = await $api.post('/user/ban/add', { login, name, time, comment });
      result = result.data;

      if (result.name != '') {
        toast.success(`Аккаунт [${result.login}] персонажа [${result.name}] успешно заблокирован. Время: ${getTimeString(result.time)}`);
      }
      else {
        toast.success(`Аккаунт [${result.login}] успешно заблокирован. Время: ${getTimeString(result.time)}`);
      }
      
      await getApiData();
    } catch (e) {
      toast.error(e.response?.data?.message);
    }

    setLoadingData(false);
  };

  const sendDel = async (login) => {
    setLoadingData(true);

    try {
      await $api.post('/user/ban/del', { login });
      toast.success(`Аккаунт разблокирован.`);
      await getApiData();
    } catch (e) {
      toast.error(e.response?.data?.message);
    }

    setLoadingData(false);
  };

  const [cookies, setCookie] = useCookies(['mainTab']);

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Блокировки</h2>
        <div className="mb-4"></div>
      </div>
      <div className="mx-lg-n4 mb-3">
        <Row className="g-3">
          <Col xs={12} xl={12} xxl={12} align="middle">
            <Card>
              <Card.Body>
                <p className="text-700 mb-4">Можно блокировать по логину или никнейму.</p>
                <p className="text-700 mb-0 fw-bold">Время:</p>
                <p className="text-700 mb-0">-1 : Учетная запись заблокирована.</p>
                <p className="text-700 mb-0">0 : Учетная запись заблокирована навсегда.</p>
                <p className="text-700 mb-4">1+ : Учетная запись заблокирована на N д.</p>
                <div className="d-flex flex-wrap gap-3">
                  <Form.Control
                    type="string"
                    placeholder="Введите логин..."
                    value={login}
                    onChange={({ target }) => {
                      setLogin(target.value);
                    }}
                  />
                  <Form.Control
                    type="string"
                    placeholder="Введите никнейм..."
                    value={name}
                    onChange={({ target }) => {
                      setName(target.value);
                    }}
                  />
                  <Form.Control
                    type="string"
                    placeholder="Введите время..."
                    value={time}
                    onChange={({ target }) => {
                      setTime(target.value);
                    }}
                  />
                  <Form.Control
                    type="string"
                    placeholder="Введите комментарий..."
                    value={comment}
                    onChange={({ target }) => {
                      setComment(target.value);
                    }}
                  />
                  <Button variant="primary" onClick={sendAdd} loading={isLoadingData} loadingPosition="start">Заблокировать</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Tab.Container defaultActiveKey={cookies.mainTab ? cookies.mainTab : "temp"} onSelect=
          {(eventKey: string | null) => {
            if (eventKey) {
              setCookie('mainTab', eventKey);
            }
          }}>
          <Nav
            variant="underline"
            className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
          >
          {navItems.map((item, index) => (
            <Nav.Item key={item.eventKey} className="me-2 text-nowrap">
              <Nav.Link eventKey={item.eventKey.toLowerCase()}>
                <FontAwesomeIcon
                  icon={item.icon}
                  className="me-2 tab-icon-color"
                />
                {item.label}
              </Nav.Link>
            </Nav.Item>
          ))}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="temp">
              <AdvanceTableProvider {...table}>
                <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                <AdvanceTableFooter pagination />
              </AdvanceTableProvider>
            </Tab.Pane>
            <Tab.Pane eventKey="no_time">
              <AdvanceTableProvider {...table2}>
                <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                <AdvanceTableFooter pagination />
              </AdvanceTableProvider>
            </Tab.Pane>
            <Tab.Pane eventKey="forever">
              <AdvanceTableProvider {...table3}>
                <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                <AdvanceTableFooter pagination />
              </AdvanceTableProvider>
            </Tab.Pane>
            <Tab.Pane eventKey="history">
              <AdvanceTableProvider {...table4}>
                <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                <AdvanceTableFooter pagination />
              </AdvanceTableProvider>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
    </div>
  );
};

export default Police;
