import { faFileExport, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import PromoCodeStatsTable, {
  promoCodeStatsTablecolumns
} from 'components/tables/PromoCodeStatsTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';

import india from 'assets/img/country/india.png';

import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Table, Col, Row, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import FeatherIcon from 'feather-icons-react';
import team1 from 'assets/img/team/32.webp';
import team2 from 'assets/img/team/33.webp';
import team3 from 'assets/img/team/34.webp';
import team4 from 'assets/img/team/35.webp';
import team5 from 'assets/img/team/24.webp';
import team6 from 'assets/img/team/29.webp';
import team7 from 'assets/img/team/65.webp';
import team8 from 'assets/img/team/63.webp';
import team9 from 'assets/img/team/31.webp';
import team10 from 'assets/img/team/26.webp';
import team11 from 'assets/img/team/73.webp';
import team12 from 'assets/img/team/75.webp';
import { BadgeBg } from 'components/base/Badge';

import { useCookies } from 'react-cookie';

export interface LeadDataType {
  id: number;
  customer: {
    avatar: string;
    name: string;
    designation: string;
    status: {
      label: string;
      type: BadgeBg;
    };
  };
  email: string;
  phone: string;
  contact: string;
  company: string;
  date: string;
}

export const leadsTableColumns: ColumnDef<LeadDataType>[] = [
  {
    accessorKey: 'customer.name',
    header: 'Название',
    cell: ({ row: { original } }) => {
      const {
        avatar,
        name,
        designation,
        status: { label, type }
      } = original.customer;
      return (
        <div className="d-flex align-items-center">
          <div>
            <p className="fs-8 fw-bold">
              {name}
            </p>
            <div className="d-flex align-items-center">
              <p className="mb-0 text-1000 fw-semi-bold fs-9 me-2">
                {designation}
              </p>
              <Badge variant="phoenix" bg={type}>
                {label}
              </Badge>
            </div>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-success-100 rounded me-2">
            <FeatherIcon
              icon="mail"
              size={16}
              className="text-success-600 dark__text-success-300"
            />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Avatar.Group total={5} size="s">
          
            <Avatar size="s" src="https://ru.hardlc.com/icon/0/0/0/0" />
          
        </Avatar.Group>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="phone"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return (
        <Link className="text-1000" to={`tel:${phone}`}>
          {phone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'contact',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-info-100 rounded me-2">
            <FeatherIcon
              icon="user"
              size={16}
              className="text-info-600 dark__text-info-300"
            />
          </div>
          <span>Contact name</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.contact,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },
  {
    accessorKey: 'company',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-warning-100 rounded me-2">
            <FeatherIcon
              icon="grid"
              size={16}
              className="text-warning-600 dark__text-warning-300"
            />
          </div>
          <span>Company name</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.company,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },
  {
    accessorKey: 'date',
    header: 'Create date',
    cell: ({ row: { original } }) => original.date,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-700'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item eventKey="1">Изменить</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4" className="text-danger">Удалить</Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  },
  {
    id: 'designation',
    accessorFn: ({ customer }) => customer.designation
  },
  {
    id: 'status',
    accessorFn: ({ customer }) => customer.status.label
  }
];



/*export const leadsTableData: LeadDataType[] = [
  {
    id: 1,
    customer: {
      avatar: team1,
      name: 'Anthoney Michael',
      designation: 'VP Accounting',
      status: {
        label: 'Действует',
        type: 'success'
      }
    },
    email: 'anth125@gmail.com',
    phone: '+1-202-555-0126',
    contact: `Ally Aagaard`,
    company: `Google.inc`,
    date: `Jan 01, 12:56 PM`
  },
  {
    id: 2,
    customer: {
      avatar: team2,
      name: 'Jacob Russell',
      designation: 'Executive Manager',
      status: {
        label: 'Закончился',
        type: 'secondary'
      }
    },
    email: 'jacob@yahoo.com',
    phone: '+1-202-555-0135',
    contact: `Alex Abadi`,
    company: `Google.inc`,
    date: `Dec 31, 11:51 PM`
  },
  /*{
    id: 3,
    customer: {
      avatar: team3,
      name: 'Diego Anthony',
      designation: 'CEO',
      status: {
        label: 'In progress',
        type: 'info'
      }
    },
    email: 'diego20@hotmail.com',
    phone: '+44 1632 960970',
    contact: `Lyla Nicole`,
    company: `Adobe Inc.`,
    date: `Dec 29, 02:11 AM`
  },
  {
    id: 4,
    customer: {
      avatar: team4,
      name: 'Austin James',
      designation: 'Creative Director',
      status: {
        label: 'Cold lead',
        type: 'warning'
      }
    },
    email: 'austin@sni.it',
    phone: '+44 1632 960970',
    contact: `Kylia Abbott`,
    company: `Facebook`,
    date: `Dec 28, 10:56 PM`
  },
  {
    id: 5,
    customer: {
      avatar: team5,
      name: 'Sarah Lynn',
      designation: 'Accountant',
      status: {
        label: 'won lead',
        type: 'success'
      }
    },
    email: 'sarah@technext.it',
    phone: '+1-202-555-0177',
    contact: `Bryce Abbott`,
    company: `Twitter`,
    date: `Dec 27, 11:43 PM`
  },
  {
    id: 6,
    customer: {
      avatar: team6,
      name: 'Reyna Denise',
      designation: 'Executive Manager',
      status: {
        label: 'new lead',
        type: 'info'
      }
    },
    email: 'reyna99@gmail.com',
    phone: '+44 1632 960958',
    contact: `Jase Adams`,
    company: `Twitter`,
    date: `Dec 25, 12:55 PM`
  },
  {
    id: 7,
    customer: {
      avatar: team7,
      name: 'Roy Anderson',
      designation: 'System Admin',
      status: {
        label: 'Canceled',
        type: 'secondary'
      }
    },
    email: 'andersonroy@netflix.chill',
    phone: '+1-613-555-0109',
    contact: `Jim Aldridge`,
    company: `Google.inc`,
    date: `Dec 24, 09:52 PM`
  },
  {
    id: 8,
    customer: {
      avatar: team8,
      name: 'Emily Beazley',
      designation: 'Marketing Executive',
      status: {
        label: 'New Lead',
        type: 'info'
      }
    },
    email: 'beazley1@gmail.com',
    phone: '+61 1900 654 321',
    contact: `Zackry Aldridge`,
    company: `Instagram`,
    date: `Dec 22, 08:45 PM`
  },
  {
    id: 9,
    customer: {
      avatar: team9,
      name: 'Layla Beckstrand',
      designation: 'Junior Executive',
      status: {
        label: 'cold lead',
        type: 'warning'
      }
    },
    email: 'layla@live.com',
    phone: '+36 55 116 068',
    contact: `Daniel Alexander`,
    company: `Linkedin`,
    date: `Dec 20, 06:32 PM`
  },
  {
    id: 10,
    customer: {
      avatar: team10,
      name: 'Olivia Bensinger',
      designation: 'Chief Tech Officer',
      status: {
        label: 'won lead',
        type: 'success'
      }
    },
    email: 'olivia1986@gmail.com',
    phone: '+44 1632 960248',
    contact: `Sam Alimentato`,
    company: `Google.inc`,
    date: `Dec 19, 10:44 PM`
  },

  {
    id: 11,
    customer: {
      avatar: team11,
      name: 'Emily Beazley',
      designation: 'Marketing Executive',
      status: {
        label: 'New Lead',
        type: 'info'
      }
    },
    email: 'beazley1@gmail.com',
    phone: '+61 1900 654 321',
    contact: `Zackry Aldridge`,
    company: `Instagram`,
    date: `Dec 22, 08:45 PM`
  },
  {
    id: 12,
    customer: {
      avatar: team12,
      name: 'Roy Anderson',
      designation: 'System Admin',
      status: {
        label: 'Canceled',
        type: 'secondary'
      }
    },
    email: 'andersonroy@netflix.chill',
    phone: '+1-613-555-0109',
    contact: `Jim Aldridge`,
    company: `Google.inc`,
    date: `Dec 24, 09:52 PM`
  }*
];*/



const tabItems: FilterTabItem[] = [
  {
    label: 'Все',
    value: 'all',
    count: -1
  },
  {
    label: 'Активные',
    value: 'published',
    count: -1
  },
  {
    label: 'Неактивные',
    value: 'drafts',
    count: -1
  }
];

const filterMenus: FilterMenu[] = [
  {
    label: 'Промокод',
    items: [
      {
        label: 'Plants'
      },
      {
        label: 'Furniture'
      },
      {
        label: 'Fashion'
      }
    ]
  },
  {
    label: 'Персонажи',
    items: [
      {
        label: 'Все'
      },
      {
        label: 'Существующие'
      },
      {
        label: 'Удалённые'
      }
    ]
  }
];


const columns: ColumnDef<TopRegionsTableDataType>[] = [
  {
    header: 'Название',
    accessorFn: rowData => rowData.country.name,
    cell: ({ row }) => {
      const serial = row.index + 1;
      const { country } = row.original;
      return (
        <div className="d-flex align-items-center">
          <h6 className="mb-0 me-3">{serial}.</h6>
          <Link to="#!">
            <div className="d-flex justify-content-center">
              <p className="mb-0 ps-3 text-primary fw-bold fs-9">
                {country.name}
              </p>
            </div>
          </Link>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '32%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap align-middle ps-0' }
    }
  },
  {
    header: 'Новички',
    accessorFn: rowData => rowData.users.number,
    cell: ({ row: { original } }) => {
      const { users } = original;
      return (
        <h6 className="mb-0">
          {users.number}
          <span className="text-700 fw-semi-bold ms-2">
            ({users.percantage})
          </span>
        </h6>
      );
    },
    meta: {
      headerProps: { style: { width: '17%' } },
      cellProps: { className: 'align-middle' }
    }
  },
  {
    header: 'Старички',
    accessorFn: rowData => rowData.transactions.number,
    cell: ({ row: { original } }) => {
      const { transactions } = original;
      return (
        <h6 className="mb-0">
          {transactions.number}
          <span className="text-700 fw-semi-bold ms-2">
            ({transactions.percantage})
          </span>
        </h6>
      );
    },
    meta: {
      cellProps: { className: 'text-end' },
      headerProps: {
        style: { width: '16%' },
        className: 'text-end align-middle'
      }
    }
  },
  {
    header: 'Общее',
    accessorFn: rowData => rowData.revenue.number,
    cell: ({ row: { original } }) => {
      const { revenue } = original;
      return (
        <h6 className="mb-0">
          {revenue.number}
        </h6>
      );
    },
    meta: {
      cellProps: { className: 'text-end' },
      headerProps: {
        style: { width: '20%' },
        className: 'text-end align-middle'
      }
    }
  },
  /*{
    header: 'CONV. RATE',
    accessorFn: rowData => rowData.convRate,
    cell: ({ row: { original } }) => {
      const { convRate } = original;
      return <h6 className="mb-0">{convRate}</h6>;
    },
    meta: {
      cellProps: { className: 'text-end pe-0' },
      headerProps: {
        style: { width: '17%' },
        className: 'text-end align-middle pe-0'
      }
    }
  }*/
];

const PromoCodeStats = () => {
  let data2: any[] = [];
  //const [data2, setData2] = useState<any[]>([]);
  const [productsTableData, setProductsTableData] = useState<ProductsTableProductType[]>([]);
  const [topRegionsTableData, setTopRegionsTableData] = useState<ProductsTableProductType[]>([]);
  const [leadsTableData, setLeadsTableData] = useState<LeadDataType[]>([]);
  const [info, setInfo] = useState<any>({ new: 0, old: 0, total: 0 });

  const table = useAdvanceTable({
    data: productsTableData,
    columns: promoCodeStatsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    //selection: true
  });

  const setTableData = async () => {
    console.log(data2);

    const array = [];
    const array2 = {};
    const array3 = [];
    const array4 = [];

    let currentDate = dayjs().subtract(1, 'month');

    //console.log(currentDate);

    for (let i = 0; i < data2.length; i++) {
      let characters = [];
      let isNew = false;

      if (dayjs(data2[i].first_connect_date) >= currentDate) {
        isNew = true;
      }

      for (let i2 = 0; i2 < data2[i].characters.length; i2++) {


        if (data2[i].characters[i2].a_enable != 1) {
          continue;
        }

        /*if (data2[i].characters[i2].a_level < 50) {
          isNew = true;
        }*/

        characters.push(`${data2[i].characters[i2].a_nick} (${data2[i].characters[i2].a_level} ур.)`);
      }

        array.push({
          product: `${data2[i].user_id}`,
          //productImage: product1,
          //price: 39,
          category: data2[i].a_code,
          tags: characters,
          //starred: false,
          //vendor: 'Blue Olive Plant sellers. Inc',
          publishedOn: dayjs(data2[i].a_date).format('DD.MM.YYYY HH:mm:ss')
        });

        if (isNew) {
          info.new++;
        }
        else {
          info.old++;
        }

        info.total++;

        setInfo(info);


        if (array2[data2[i].a_code] == undefined) {
          array2[data2[i].a_code] = {
            new: isNew ? 1 : 0,
            old: isNew ? 0 : 1,
            total: 1,
          };
        }
        else {
          if (isNew) {
            array2[data2[i].a_code].new++;
          }
          else {
            array2[data2[i].a_code].old++;
          }
          array2[data2[i].a_code].total++;
        }
    }

    setProductsTableData(array);

    for (let i = 0; i < Object.values(array2).length; i++) {

        array3.push({
          country: {
            name: Object.keys(array2)[i]
          },
          users: {
            number: Object.values(array2)[i].new,
            percantage: `${(Object.values(array2)[i].new / Object.values(array2)[i].total * 100).toFixed(2)}%`
          },
          transactions: {
            number: Object.values(array2)[i].old,
            percantage: `${(Object.values(array2)[i].old / Object.values(array2)[i].total * 100).toFixed(2)}%`
          },
          revenue: {
            number: Object.values(array2)[i].total
          },
          /*convRate: '14.01%'*/
        });



        array4.push({
          id: 1,
          customer: {
            avatar: team1,
            name: Object.keys(array2)[i],
            designation: 'VP Accounting',
            status: {
              label: 'Действует',
              type: 'success'
            }
          },
          email: 'anth125@gmail.com',
          phone: '+1-202-555-0126',
          contact: `Ally Aagaard`,
          company: `Google.inc`,
          date: `Jan 01, 12:56 PM`
        });
    }


    setTopRegionsTableData(array3);
     setLeadsTableData(array4);


  };

  const getApiData = async () => {
    let result = await $api.get('/promocode/log');

    const array = [];

    for (let i = 0; i < Object.values(result.data).length; i++) {

        array.push(Object.values(result.data)[i]);
    }

    //console.log(array);

    data2 = array;
    setTableData();
  };

  useEffect(() => {
    getApiData();
  }, []);



  const table2 = useAdvanceTable({
    data: topRegionsTableData,
    columns,
    pageSize: 5,
    pagination: true,
    selectionColumnWidth: '30px',
    sortable: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleSearchInputChange2 = (e: ChangeEvent<HTMLInputElement>) => {
    table2.setGlobalFilter(e.target.value || undefined);
  };

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);



  

  const { getRowModel, getFlatHeaders } = table2;







  const table3 = useAdvanceTable({
    data: leadsTableData,
    columns: leadsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    //selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });


  const [cookies, setCookie] = useCookies(['name']);


  return (
    <div>

<div>
      <Form.Control onChange={({ target }) => {
            

            setCookie('name', target.value);
          }} />
      {cookies.name && <h1>Hello {cookies.name}!</h1>}
    </div>






      <div className="mb-9">
        <h2 className="mb-4">Промокод</h2>





        {false && (<AdvanceTableProvider {...table3}>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Button
                variant="primary"
                className="me-4"
                startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
              >
                Создать
              </Button>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />                
              </div>
            </Col>
          </Row>
          <div className="border-top">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              rowClassName="hover-actions-trigger btn-reveal-trigger"
            />
            <AdvanceTableFooter pagination className="py-4" />
          </div>
        </AdvanceTableProvider>
        )}






        <FilterTab tabItems={tabItems} className="mb-2" />
        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Поиск..."
                onChange={handleSearchInputChange}
              />
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup menus={filterMenus} />
              </div>
              <Button
                  variant="phoenix-secondary"
                  className="px-3"
                  onClick={() => setOpenFilterModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    transform="down-3"
                    className="text-primary"
                  />
                </Button>
                <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />







    <Modal show={openAddModal} className="p-0" centered>
      <Modal.Header className="border-200 p-4">
        <h5 className="modal-title text-1000 fs-6 lh-sm">Добавление</h5>
        <Button className="p-1">
          <FontAwesomeIcon className="fs-9" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-2 px-4">
        
      </Modal.Body>
    </Modal>














            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <PromoCodeStatsTable />
          </div>
        </AdvanceTableProvider>

        
        <AdvanceTableProvider {...table2}>
          <div className="mt-4 mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Поиск..."
                onChange={handleSearchInputChange2}
              />
            </div>
          </div>

          {/* <Scrollbar autoHeight autoHeightMax="100%"> */}
          <div className="scrollbar">
            <Table className="fs-10 mb-0 border-top scrollbar">
              <thead>
                <tr>
                  {getFlatHeaders().map(header => {
                    return (
                      <th
                        key={header.id}
                        {...header.column.columnDef.meta?.headerProps}
                        className={classNames(
                          'sort',
                          header.column.columnDef.meta?.headerProps?.className,
                          {
                            desc: header.column.getIsSorted() === 'desc',
                            asc: header.column.getIsSorted() === 'asc'
                          }
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td className="py-4">
                    <h4 className="mb-0 fw-normal">{new Intl.NumberFormat().format(info.new)}</h4>
                  </td>
                  <td className="text-end py-4">
                    <h4 className="mb-0 fw-normal">{new Intl.NumberFormat().format(info.old)}</h4>
                  </td>
                  <td className="text-end py-4">
                    <h4 className="mb-0 fw-normal">{new Intl.NumberFormat().format(info.total)}</h4>
                  </td>
                </tr>
                {getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <AdvanceTableFooter className="gx-0" navBtn showViewAllBtn={false} />
          {/* </Scrollbar> */}
        </AdvanceTableProvider>







      </div>
    </div>
  );
};

export default PromoCodeStats;
