import Avatar from 'components/base/Avatar';
import { useState, useContext } from 'react';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/72x72/57.webp';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import {Context} from "index";
import Button from 'components/base/Button';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const [navItems] = useState([
    {
      label: 'Профиль',
      icon: 'user'
    }
  ]);
  const {store} = useContext(Context);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={store.user.avatar} size="xl" />
            <h6 className="text-black">{store.user.email}</h6>
          </div>
          <div style={{ height: '3rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          <br />
          <div className="px-3">
          <Button className="btn btn-phoenix-secondary d-flex flex-center w-100" onClick={() => store.logout()}>
            <FeatherIcon icon="log-out" className="me-2" size={16} />
            Выйти
          </Button>


          </div>
          <br />
        </Card.Footer>





      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
