import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose,
  faEdit,
  faTasks,
  faTrash
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { ChangeEvent, useEffect, useState, useCallback } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Card, Table, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import Badge from 'components/base/Badge';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChess, faDove, faDungeon } from '@fortawesome/free-solid-svg-icons';

const promoCodeStatsTablecolumns: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_index',
    header: 'Id',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_name',
    header: 'Название',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
];

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)










import { ToDoItem } from 'data/project-management/todoListData';

type Breakpoints = 'md' | 'lg' | 'xl' | 'xxl';

interface TodoListItemInterface {
  todo: ToDoItem;
  className?: string;
  labelClassName?: string;
  halfLayoutBreakpoints?: Breakpoints[];
  fullLayoutBreakpoints?: Breakpoints[];
  onClick?: (item: ToDoItem) => void;
}
type FullLayoutBreakpoints = {
  [key in Breakpoints]?: 'auto';
};
type HalfLayoutBreakpoints = {
  [key in Breakpoints]?: 12;
};

const TodoListItem = ({
  todo,
  className,
  labelClassName,
  halfLayoutBreakpoints = [],
  fullLayoutBreakpoints = [],
  onClick
}: TodoListItemInterface) => {
  const [selected, setSelected] = useState(todo.completed);

  const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.checked);
  };

  const handleClick = (item: ToDoItem) => {
    if (onClick) {
      onClick(item);
    } else {
      setSelected(!selected);
    }
  };

  const getBreakpointClasses = useCallback(
    (className: string, values: (number | string)[]) =>
      [
        ...halfLayoutBreakpoints.map(
          breakpoint => `${className}-${breakpoint}-${values[0]}`
        ),
        ...fullLayoutBreakpoints.map(
          breakpoint => `${className}-${breakpoint}-${values[1]}`
        )
      ].join(' '),
    [halfLayoutBreakpoints, fullLayoutBreakpoints]
  );

  return (
    <>
      <div
        className={classNames(
          className,
          'd-flex align-items-center hover-actions-trigger border-bottom gap-2 todolist-item'
        )}
      >
        <Row className="justify-content-between align-items-center btn-reveal-trigger border-200 gx-0 flex-1 gy-1">
          <Col
            xs={12}
            {...fullLayoutBreakpoints?.reduce(
              (acc: FullLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = 'auto';
                return acc;
              },
              {}
            )}
            {...halfLayoutBreakpoints?.reduce(
              (acc: HalfLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = 12;
                return acc;
              },
              {}
            )}
          >
            <div className="d-flex align-items-center lh-1 gap-2">
              <h5
                className={classNames(
                  labelClassName,
                  'mb-0 line-clamp-1 fw-semi-bold text-700 cursor-pointer',
                  {
                    'text-decoration-line-through': selected
                  }
                )}
                onClick={() => handleClick(todo)}
              >
                {`${todo.a_index} - ${todo.a_name}`}
              </h5>
              {todo.badge && (
                <Badge
                  variant="phoenix"
                  bg={todo.badge.bg}
                  className="fs-10 ms-auto"
                >
                  {todo.badge.label}
                </Badge>
              )}
            </div>
          </Col>
          <Col
            xs={12}
            {...fullLayoutBreakpoints?.reduce(
              (acc: FullLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = 'auto';
                return acc;
              },
              {}
            )}
            {...halfLayoutBreakpoints?.reduce(
              (acc: HalfLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = 12;
                return acc;
              },
              {}
            )}
          >
            <div className="d-flex lh-1 align-items-center">
              {todo.attachment && (
                <Button className="p-0 text-700 fs-10 me-2">
                  <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                  {todo.attachment}
                </Button>
              )}
              {todo.listitems && (
                <Button className="p-0 text-warning fs-10 me-2">
                  <FontAwesomeIcon icon={faTasks} className="me-1" />
                  {todo.listitems}
                </Button>
              )}
              <p
                className={classNames(
                  getBreakpointClasses('me', [2, 3]),
                  'text-700 fs-10 me-2 mb-0'
                )}
              >
                {todo.date}
              </p>
              <div
                className={classNames(
                  getBreakpointClasses('hover', ['show', 'hide'])
                )}
              >
                <p
                  className={classNames(
                    getBreakpointClasses('ps', [0, 3]),
                    'text-700 fs-10 ps-lg-3 border-start-lg border-300 fw-bold mb-md-0 mb-0'
                  )}
                >
                  {todo.time}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div
          className={classNames(
            getBreakpointClasses('d', ['none', 'block']),
            'd-none end-0 position-absolute'
          )}
          style={{ top: '23%' }}
        >
          <div className="hover-actions end-0">
            <Button variant="phoenix-secondary" className="btn-icon fs-10 me-1">
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button variant="phoenix-secondary" className="btn-icon fs-10">
              <FontAwesomeIcon icon={faTrash} className="text-danger" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};












const Police = () => {
  const [name, setName] = useState<string>('');  
  const [data, setData] = useState<ProductsTableProductType[]>([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const table = useAdvanceTable({
    data: data,
    columns: promoCodeStatsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const getApiData = async () => {
    setLoadingData(true);
    setData([]);

    let result = await $api.get('/data/items/list');
    result = result.data;

    result.sort((a, b) => a.a_index - b.a_index);

    let array = [];

    for (let i = 0; i < result.length; i++) {
      let el = result[i];
      array.push(el);
    }

    setData(array);
    setLoadingData(false);
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Предметы</h2>
        <div className="mb-4"></div>
      </div>
      <div className="mx-lg-n4 mb-3">
        <Row className="g-3">
          <Col xs={12} xl={6} xxl={6}>
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap gap-3">
                  <Form.Control
                    type="string"
                    placeholder="Введите никнейм..."
                    value={name}
                    onChange={({ target }) => {
                      setName(target.value);
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} xl={6} xxl={6}>
            <Card className="h-100 todo-list">
              {/* <Scrollbar style={{ minHeight: 300 }}> */}
              <Card.Body className="py-0 scrollbar to-do-list-body">
                {data.map((item, index) => (
                  <TodoListItem
                    key={item.a_index}
                    todo={item}
                    className={classNames({
                      'border-top': index === 0
                    })}
                    labelClassName="text-900"
                    fullLayoutBreakpoints={['md', 'xxl']}
                    halfLayoutBreakpoints={['xl']}
                  />
                ))}
              </Card.Body>
              {/* </Scrollbar> */}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Police;
