import App from 'App';
import {
  RouteObject, 
  createBrowserRouter, 
  Navigate, 
  useLocation
} from 'react-router-dom';
import { PropsWithChildren, useContext } from 'react';
import MainLayout from 'layouts/MainLayout';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Ecommerce from 'pages/dashboard/ecommerce';
import PromoCodeStats from 'pages/promocode/Stats';
import Invite from 'pages/Invite';
import Finance from 'pages/Finance';
import CashShop from 'pages/CashShop';
import FunctionsNewbie from 'pages/functions/Newbie';
import FunctionsPolice from 'pages/functions/Police';
import FunctionsBan from 'pages/functions/Ban';
import Hash from 'pages/Hash';
import OtherItems from 'pages/other/Items';
import { Context } from './index';
import StatsNas from 'pages/stats/Nas';
import StatsCash from 'pages/stats/Cash';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';

export function ProtectedRoute({ children, level }: any) {
  const { store } = useContext(Context);
  const location = useLocation();

  if (level == undefined || store.user.access_level < level) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return store.isAuth ? (
    children
  ) : (
    <Navigate to="/signin" state={{ from: location }} />
  );
}

export function PublicRoute({ children }: PropsWithChildren) {
  const { store } = useContext(Context);
  const location = useLocation();

  return store.isAuth ? (
    <Navigate to="/" state={{ from: location }} />
  ) : (
    children
  );
}

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute level={1}>
            <MainLayoutProvider>
              <MainLayout />
            </MainLayoutProvider>
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute level={2}>
                <Ecommerce />
              </ProtectedRoute>
            )
          },
          {
            path: '/promocode',
            element: (
              <ProtectedRoute level={1}>
                <PromoCodeStats />
              </ProtectedRoute>
            )
          },
          {
            path: '/invite',
            element: (
              <ProtectedRoute level={1}>
                <Invite />
              </ProtectedRoute>
            )
          },
          {
            path: '/finance',
            element: (
              <ProtectedRoute level={2}>
                <Finance />
              </ProtectedRoute>
            )
          },
          {
            path: '/hash',
            element: (
              <ProtectedRoute level={1}>
                <Hash />
              </ProtectedRoute>
            )
          },
          {
            path: '/functions',
            children: [
              {
                path: 'newbie',
                element: (
                  <ProtectedRoute level={1}>
                    <FunctionsNewbie />
                  </ProtectedRoute>
                )
              },
              {
                path: 'police',
                element: (
                  <ProtectedRoute level={1}>
                    <FunctionsPolice />
                  </ProtectedRoute>
                )
              },
              {
                path: 'ban',
                element: (
                  <ProtectedRoute level={1}>
                    <FunctionsBan />
                  </ProtectedRoute>
                )
              }
            ]
          },
          {
            path: '/other',
            children: [
              {
                path: 'items',
                element: (
                  <ProtectedRoute level={1}>
                    <OtherItems />
                  </ProtectedRoute>
                )
              }
            ]
          },
          {
            path: '/stats',
            children: [
              {
                path: 'cashshop',
                element: (
                  <ProtectedRoute level={1}>
                    <CashShop />
                  </ProtectedRoute>
                )
              },
              {
                path: 'nas',
                element: (
                  <ProtectedRoute level={1}>
                    <StatsNas />
                  </ProtectedRoute>
                )
              },
              {
                path: 'cash',
                element: (
                  <ProtectedRoute level={1}>
                    <StatsCash />
                  </ProtectedRoute>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'signin',
        element: (
          <PublicRoute>
            <SimpleSignIn />
          </PublicRoute>
        )
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
