import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Card, Table, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import { suspend } from 'suspend-react'
import Avatar from 'components/base/Avatar';
const { Image } = require('image-js');

let ItemBtn0 = require('assets/img/icons/items/ItemBtn0.png');

enum IconType {
  Item = 1,
  Skill,
}

let isInitIcons = false;
let icons = [];

async function getIcon(type: IconType, id: number, row: number, col: number) {

  while (!isInitIcons && (icons[id] == undefined || icons[id][row] == undefined || icons[id][row][col] == undefined)) {
    await new Promise(r => setTimeout(r, 200));
  }

  //console.log(id, row, col);

  return icons[id][row][col] == undefined ? icons[0][0][0] : icons[id][row][col];
}

async function initIcons() {

  for (let id = 0; ; id++) {
    let file = null;

    try {
     file = require(`assets/img/icons/items/ItemBtn${id}.png`);
    } catch (err) {
     break;
    }

    //console.log(`assets/img/icons/items/ItemBtn${id}.png`);

    let image = await Image.load(file);

    icons[id] = [];

    for (let row = 0; row < image.height / 32; row++) {
      icons[id][row] = [];

      for (let col = 0; col < image.width / 32; col++) {
        icons[id][row][col] = image.crop({ x: col * 32 + 2, y: row * 32 + 2, width: 28, height: 28 }).toDataURL();
      }
    }
  }  

  //console.log(`isInitIcons`);

  isInitIcons = true;
}

initIcons();

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var weekOfYear = require('dayjs/plugin/weekOfYear')
var isBetween = require('dayjs/plugin/isBetween')
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

//dayjs.tz.setDefault("Europe/Moscow")

const promoCodeStatsTablecolumns: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_ctname',
    header: 'Название',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    id: 'items',
    cell: ({ row: { original } }) => {
      const { items } = original;

      return (
        <>
        {items.length > 0  ? (
          <Avatar.Group total={items.length} size="s">
            {items.map(item => (
              <Avatar key={item.a_index} size="s" src={item.img} />
            ))}
          </Avatar.Group>
        ) : (
          <Spinner animation="border" size="sm" />
        )}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: 80 } },
      //cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Перв. - послед. дата покупки',
    cell: ({ row: { original } }) => {
      const { dateStart, dateEnd } = original;
      return `${dayjs(dateStart).utc().format('DD.MM.YYYY HH:mm')} - ${dayjs(dateEnd).utc().format('DD.MM.YYYY HH:mm')}`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'count',
    header: 'Количество',
    cell: ({ row: { original } }) => {
      const { count } = original;
      return new Intl.NumberFormat().format(count);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'cash',
    header: 'Потрачено шопа',
    cell: ({ row: { original } }) => {
      const { cash, count } = original;
      return `${new Intl.NumberFormat().format(cash * count)} (${new Intl.NumberFormat().format(cash)} за 1 шт.)`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  }, 
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      const { callback } = original;
      return (
        <Button
          variant="phoenix-secondary"
          className="px-3"
          onClick={callback}
        >
          <FontAwesomeIcon
            icon={faPlus}
            transform="down-3"
            className="text-primary"
          />
        </Button>
      );
    },
    meta: {
      headerProps: { style: { width: 60 } },
      cellProps: { className: 'text-end' }
    }
  }
];

const promoCodeStatsTablecolumns2: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_ctname',
    header: 'Название',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Перв. - послед. дата покупки',
    cell: ({ row: { original } }) => {
      const { dateStart, dateEnd } = original;
      return `${dayjs(dateStart).utc().format('DD.MM.YYYY HH:mm')} - ${dayjs(dateEnd).utc().format('DD.MM.YYYY HH:mm')}`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'count',
    header: 'Количество',
    cell: ({ row: { original } }) => {
      const { count } = original;
      return new Intl.NumberFormat().format(count);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'cash',
    header: 'Потрачено шопа',
    cell: ({ row: { original } }) => {
      const { cash, count } = original;
      return `${new Intl.NumberFormat().format(cash * count)} (${new Intl.NumberFormat().format(cash)} за 1 шт.)`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  }
];

const CashShop = () => {
  let [itemsList, setItemsList] = useState<any[]>([]);
  let [catalogList, setCatalogList] = useState<any[]>([]);
  let [purchasesList, setPurchasesList] = useState<any[]>([]);


  let [img, setImg] = useState<any>(null);

  const [productsTableData, setProductsTableData] = useState<ProductsTableProductType[]>([]);
  const [productsTableData2, setProductsTableData2] = useState<ProductsTableProductType[]>([]);

  const [filterDateMin, setFilterDateMin] = useState<string>(dayjs().utc().format('DD.MM.YYYY'));
  let filterDateStart: any = undefined;
  let filterDateEnd: any = undefined;

  const [openAddModal, setOpenAddModal] = useState(false);

  const table = useAdvanceTable({
    data: productsTableData,
    columns: promoCodeStatsTablecolumns,
    pageSize: 100,
    pagination: true,
    sortable: true
  });

  const table2 = useAdvanceTable({
    data: productsTableData2,
    columns: promoCodeStatsTablecolumns2,
    pageSize: 100,
    pagination: true,
    sortable: true
  });

  const testOpenModal = async (data) => {
    setTableData2();
    setOpenAddModal(true);
  };

  const setTableData = async () => {

    //console.log(`setTableData: ${purchasesList.length}, ${catalogList.length}, ${filterDateStart}, ${filterDateEnd}`)

    //console.log(`date: ${purchasesList[0].a_pdate}, ${new Date(purchasesList[0].a_pdate)}, ${dayjs(purchasesList[0].a_pdate)}`)
    //console.log(dayjs(purchasesList[0].a_pdate).tz("Europe/Moscow"))

    let dateStart = filterDateStart;
    let dateEnd = filterDateEnd/*dayjs(filterDateEnd).hour(59).minute(59).second(59)*/;

    const array = [];

    for (let i = 0; i < purchasesList.length; i++) {
      let el = purchasesList[i];
      
      if (filterDateStart != undefined && filterDateEnd != undefined) {

        /*console.log(el.a_pdate)
        console.log(dayjs.tz(el.a_pdate))

        return;*/

        if (!el.a_pdate.isBetween(dateStart, dateEnd, 'day', '[]')) {
          continue;
        }


        
        /*let tres = new Date(el.a_pdate);

        /*if ( tres >= uno && tres <= dos) {
          // do something
        }*

        console.log(`uno: ${uno}`)
        console.log(`dos: ${dos}`)
        console.log(`tres: ${tres}`)


        console.log(el.a_pdate)
        console.log(dayjs(el.a_pdate).utc())
        console.log(dayjs(el.a_pdate).utc().isBetween(filterDateStart, dayjs(filterDateEnd).add(1, 'day').subtract(1, 'millisecond'), 'day', '[]'))

        console.log(dayjs(el.a_pdate).utc().isSameOrAfter(filterDateStart, 'day'))
        console.log(dayjs(el.a_pdate).utc().isSameOrBefore(dayjs(filterDateEnd).add(1, 'day').subtract(1, 'millisecond'), 'day'))

        /*if (dayjs(el.a_pdate).utc().diff(filterDateStart, 'day') < 0) {
          
        }/*

        return;

        //console.log(`1`)

        continue;*/
      }

      //let catalogData = catalogList.find(el2 => el2.a_ctid == el.a_ctid);

      let elFind = array.find(el2 => el2.a_ctid == el.a_ctid);

      if (elFind == undefined) {
        //console.log(`2`)

        let items = [];

        //console.log(el.catalogData && el.catalogData.items);

        if (el.catalogData && el.catalogData.items) {
          for (let i2 = 0; i2 < el.catalogData.items.length; i2++) {
            let item = itemsList.find(el2 => el2.a_index == el.catalogData.items[i2].a_item_idx);



            if (item) {
              item.img = await getIcon(IconType.Item, item.a_texture_id, item.a_texture_row, item.a_texture_col);
              items.push(item);
            }
          }

          //console.log(items);
        } 

        array.push({
          a_ctid: el.a_ctid,
          a_ctname: el.catalogData ? el.catalogData.a_ctname : '',
          count: 1,
          cash: el.catalogData ? el.catalogData.a_cash : 0,
          dateStart: el.a_pdate,
          dateEnd: el.a_pdate,
          items: items,
          callback: testOpenModal,
        });
      }
      else {
        elFind.count += 1;

        if (el.a_pdate.isBefore(elFind.dateStart, 'second')) {
          elFind.dateStart = el.a_pdate;
        }

        if (el.a_pdate.isAfter(elFind.dateEnd, 'second')) {
          elFind.dateEnd = el.a_pdate;
        }
      }
    }

    setProductsTableData(array);
  };

  const setTableData2 = async () => {

    //console.log(`setTableData: ${purchasesList.length}, ${catalogList.length}, ${filterDateStart}, ${filterDateEnd}`)

    //console.log(`date: ${purchasesList[0].a_pdate}, ${new Date(purchasesList[0].a_pdate)}, ${dayjs(purchasesList[0].a_pdate)}`)
    //console.log(dayjs(purchasesList[0].a_pdate).tz("Europe/Moscow"))

    let dateStart = filterDateStart;
    let dateEnd = filterDateEnd/*dayjs(filterDateEnd).hour(59).minute(59).second(59)*/;

    const array = [];

    for (let i = 0; i < purchasesList.length; i++) {
      let el = purchasesList[i];
      
      if (filterDateStart != undefined && filterDateEnd != undefined) {

        /*console.log(el.a_pdate)
        console.log(dayjs.tz(el.a_pdate))

        return;*/

        if (!el.a_pdate.isBetween(dateStart, dateEnd, 'day', '[]')) {
          continue;
        }


        
        /*let tres = new Date(el.a_pdate);

        /*if ( tres >= uno && tres <= dos) {
          // do something
        }*

        console.log(`uno: ${uno}`)
        console.log(`dos: ${dos}`)
        console.log(`tres: ${tres}`)


        console.log(el.a_pdate)
        console.log(dayjs(el.a_pdate).utc())
        console.log(dayjs(el.a_pdate).utc().isBetween(filterDateStart, dayjs(filterDateEnd).add(1, 'day').subtract(1, 'millisecond'), 'day', '[]'))

        console.log(dayjs(el.a_pdate).utc().isSameOrAfter(filterDateStart, 'day'))
        console.log(dayjs(el.a_pdate).utc().isSameOrBefore(dayjs(filterDateEnd).add(1, 'day').subtract(1, 'millisecond'), 'day'))

        /*if (dayjs(el.a_pdate).utc().diff(filterDateStart, 'day') < 0) {
          
        }/*

        return;

        //console.log(`1`)

        continue;*/
      }

      //let catalogData = catalogList.find(el2 => el2.a_ctid == el.a_ctid);

      let elFind = array.find(el2 => el2.a_ctid == el.a_ctid);

      if (elFind == undefined) {
        //console.log(`2`)



        array.push({
          a_ctid: el.a_ctid,
          a_ctname: el.catalogData ? el.catalogData.a_ctname : '',
          count: 1,
          cash: el.catalogData ? el.catalogData.a_cash : 0,
          dateStart: el.a_pdate,
          dateEnd: el.a_pdate,
          //items: items,
          callback: testOpenModal,
        });
      }
      else {
        elFind.count += 1;

        if (el.a_pdate.isBefore(elFind.dateStart, 'second')) {
          elFind.dateStart = el.a_pdate;
        }

        if (el.a_pdate.isAfter(elFind.dateEnd, 'second')) {
          elFind.dateEnd = el.a_pdate;
        }
      }
    }

    setProductsTableData2(array);
  };

  const getApiData = async () => {

    //let image = await execute();

    //setImg(image);



    let result = $api.get('/data/items/list');
    let result2 = $api.get('/data/catalog/list');
    let result3 = $api.get('/cashshop/purchases/list');

    let resultAll = await Promise.all([result, result2, result3]);

    //console.log(result4);
    //return;

    itemsList = resultAll[0].data;
    setItemsList(itemsList);

    catalogList = resultAll[1].data;
    setCatalogList(catalogList);

    for (let i = 0; i < catalogList.length; i++) {
      catalogList[i].a_cash = parseInt(catalogList[i].a_cash);
    }

    purchasesList = resultAll[2].data;
    setPurchasesList(purchasesList);




    //console.log(purchasesList[0].a_pdate);
    //console.log(dayjs(purchasesList[0].a_pdate));
    //console.log(dayjs.tz(purchasesList[0].a_pdate));
    //console.log(dayjs(purchasesList[0].a_pdate).tz("Europe/Moscow"));
    //console.log(dayjs(purchasesList[0].a_pdate).utc());
    //console.log(dayjs(purchasesList[0].a_pdate).utc().format('DD.MM.YYYY HH:mm:ss'));
    //console.log(dayjs(dayjs(purchasesList[0].a_pdate).utc().format('YYYY-MM-DD HH:mm:ss')));



    let minData = dayjs().utc();

    for (let i = 0; i < purchasesList.length; i++) {
      let el = purchasesList[i];
      el.a_pdate = dayjs(dayjs(el.a_pdate).utc().format('YYYY-MM-DD HH:mm:ss'));


      //el.checkData = dayjs/*.tz*/(el.a_pdate);

      el.catalogData = catalogList.find(el2 => el2.a_ctid == el.a_ctid);

      if (dayjs(el.a_pdate).utc().diff(minData, 'day') < 0) {
        minData = dayjs(el.a_pdate).utc();
      }
    }

    setFilterDateMin(dayjs(minData).utc().format('DD.MM.YYYY'));

    setTableData();
  };

  useEffect(() => {
    getApiData();
  }, []);

  //const [cookies, setCookie] = useCookies(['datePickerStart', 'datePickerEnd']);

  const dataPickerChange = (dates) => {
    const [start, end] = dates;
    filterDateStart = start;
    filterDateEnd = end;

    //setCookie('datePickerStart', filterDateStart);
    //setCookie('datePickerEnd', filterDateEnd);

    //setCookie('datePicker', `${dayjs(filterDateStart).utc().format('DD.MM.YYYY')} — ${dayjs(filterDateEnd).utc().format('DD.MM.YYYY')}`);

    /*console.log(dayjs(filterDateStart));
    console.log(dayjs(filterDateStart).utc());
    console.log(dayjs(filterDateStart).utc().format('DD.MM.YYYY'));

    console.log(dayjs(filterDateEnd));
    console.log(dayjs(filterDateEnd).utc());
    console.log(dayjs(filterDateEnd).utc().format('DD.MM.YYYY'));*/

    /*const date = dayjs(dayjs(purchasesList[0].a_pdate).utc().format('YYYY-MM-DD HH:mm:ss'));
    const dateStart = filterDateStart;
    const dateEnd = filterDateEnd;

    console.log(`date: ${purchasesList[0].a_pdate}, ${filterDateStart}, ${filterDateEnd}`)
    console.log(date);
    console.log(dateStart);
    console.log(dateEnd);
    console.log(date.isBetween(dateStart, dateEnd, 'day', '[]'));*/
    
    setTableData();
  };

  
  

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Покупки в игровом магазине</h2>
        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox placeholder="Поиск..." onChange={({ target }) => { table.setGlobalFilter(target.value || undefined); }} />

              <DatePicker
                options={{
                  mode: "range",
                  minDate: filterDateMin,
                  dateFormat: "d.m.Y",
                }}
                onChange={dataPickerChange}
                placeholder="Выберите даты"
              />


            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <div>
              <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
              <AdvanceTableFooter pagination />
            </div>
          </div>
        </AdvanceTableProvider>




        <Modal show={openAddModal} fullscreen className="p-0" centered>
          <Modal.Header>
            <Modal.Title>Подробная информация</Modal.Title>
            <Button
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setOpenAddModal(false)}
            />

            <Button
              variant="phoenix-secondary"
              className="px-3"
              onClick={() => setOpenAddModal(false)}
            >
              <FontAwesomeIcon
                icon={faClose}
                transform="down-3"
                className="text-primary"
              />
            </Button>
          </Modal.Header>


          
          <Modal.Body className="pt-4 pb-2 px-4">
            



          <AdvanceTableProvider {...table2}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox placeholder="Поиск..." onChange={({ target }) => { table2.setGlobalFilter(target.value || undefined); }} />

              <DatePicker
                options={{
                  mode: "range",
                  minDate: filterDateMin,
                  dateFormat: "d.m.Y",
                }}
                onChange={dataPickerChange}
                placeholder="Выберите даты"
              />


            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <div>
              <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
              <AdvanceTableFooter pagination />
            </div>
          </div>
        </AdvanceTableProvider>


          </Modal.Body>
        </Modal>


      </div>
    </div>
  );
};

export default CashShop;
