import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Form, Card, Table, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChess, faDove, faDungeon } from '@fortawesome/free-solid-svg-icons';

export interface ActivityTimelineData {
  id: number;
  oppositeContent: {
    date: string;
    time: string;
  };
  content: {
    title: string;
    user: string;
    details: string;
  };
  separator: {
    icon: IconProp;
  };
}

/*export const activityTimelineData: ActivityTimelineData[] = [
  {
    id: 1,
    oppositeContent: {
      date: '01 DEC, 2023',
      time: '10:30 AM'
    },
    content: {
      title: 'Phoenix Template: Unleashing Creative Possibilities',
      user: 'Shantinon Mekalan',
      details:
        'Discover limitless creativity with the Phoenix template! Our latest update offers an array of innovative features and design options.'
    },
    separator: {
      icon: faChess
    }
  },
  {
    id: 2,
    oppositeContent: {
      date: '05 DEC, 2023',
      time: '12:30 AM'
    },
    content: {
      title: 'Empower Your Digital Presence: The Phoenix Template Unveiled',
      user: 'Bookworm22',
      details:
        'Unveiling the Phoenix template, a game-changer for your digital presence. With its powerful features and sleek design,'
    },
    separator: {
      icon: faDove
    }
  },
  {
    id: 3,
    oppositeContent: {
      date: '15 DEC, 2023',
      time: '2:30 PM'
    },
    content: {
      title: 'Phoenix Template: Simplified Design, Maximum Impact',
      user: 'Sharuka Nijibum',
      details:
        'Introducing the Phoenix template, where simplified design meets maximum impact. Elevate your digital presence with its sleek and intuitive features.'
    },
    separator: {
      icon: faDungeon
    }
  }
];*/

import Timeline from 'components/base/Timeline';

interface ActivityTimelineProps {
  data: ActivityTimelineData[];
}

const ActivityTimeline = ({ data }: ActivityTimelineProps) => {
  return (
    <Timeline variant="vertical">
      {data.map((item, index) => (
        <Timeline.Item className="position-relative" key={item.id}>
          <Row className="g-md-3">
            <Col xs={12} md="auto" className="d-flex">
              <Timeline.OppositeContent>
                <p className="fs-10 fw-semi-bold text-600 text-end">
                  {item.oppositeContent.date}
                  <br className="d-none d-md-block" />{' '}
                  {item.oppositeContent.time}
                </p>
              </Timeline.OppositeContent>
              <Timeline.Separator className="position-md-relative">
                <Timeline.Dot className="bg-primary-100">
                  <FontAwesomeIcon
                    icon={item.separator.icon}
                    className="text-primary-600 fs-10 dark__text-primary-300"
                  />
                </Timeline.Dot>
                {index !== data.length - 1 && (
                  <Timeline.Bar className="h-100 border-dashed border-400" />
                )}
              </Timeline.Separator>
            </Col>
            <Col>
              <Timeline.Content>
                <h5 className="fs-9 lh-sm">Персонажи:</h5>
                {item.content.characters.map((item2, index2) => (
                  <p className={classNames('fs-9 text-800 mb-0', {
                      'mb-0': index !== data.length - 1,
                      'mb-0': index === data.length - 1
                    })}
                  >
                    {item2.nick} ({item2.level} ур.)
                  </p>
                ))}
              </Timeline.Content>
            </Col>
          </Row>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

const CashShop = () => {
  const [name, setName] = useState<string>('БОББИ');
  const [data, setData] = useState<ActivityTimelineData[]>([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const getApiData = async () => {
    setLoadingData(true);
    setData([]);

    let result = await $api.post('/users/checkNewbie', { name });
    console.log(result);

    let array = [];

    for (let i = 0; i < result.data.data.length; i++) {
      let el = result.data.data[i];

      array.push({
        id: 1,
        oppositeContent: {
          date: el.account.login,
          time: el.account.date
        },
        content: {
          characters: el.characters
        },
        separator: {
          icon: faDove
        }
      });
    }

    setData(array);
    setLoadingData(false);
  };

  const sendApiData = async () => {
    let result = await $api.post('/users/unsetNewbie', { name });
    console.log(result);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Проверка на новичка</h2>
        <div className="mb-4">
        
        </div>
        
      </div>


      <div className="mx-lg-n4 mb-3">
        <Row className="g-3">
          <Col xs={12} xl={6} xxl={6}>

            <Card>
              <Card.Body>
                

<div className="d-flex flex-wrap gap-3">
            <Form.Control
              type="string"
              placeholder="Поиск..."
              value={name}
              onChange={({ target }) => {
                setName(target.value);
              }}
            />
            <Button variant="primary" onClick={getApiData} loading={isLoadingData} loadingPosition="start">Проверить</Button>
            <Button variant="success" onClick={sendApiData} disabled={isLoadingData}>Проверен</Button>
          </div>

              </Card.Body>
            </Card>



            


          </Col>
          <Col xs={12} xl={6} xxl={6}>
            <Card className="h-100">
              <Card.Body>
                <p className="text-700 mb-4"></p>
                <ActivityTimeline data={data} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CashShop;
