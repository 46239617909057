import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  adminLevel: number;
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  adminLevel: number;
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    adminLevel: 1,
    label: 'основное',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        adminLevel: 1,
        name: 'главная',
        icon: 'home',
        path: '/',
        active: true
      },
      {
        adminLevel: 2,
        name: 'Финансы',
        icon: 'home',
        path: '/finance',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Промокоды',
        icon: 'home',
        path: '/promocode',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Инвайты',
        icon: 'home',
        path: '/invite',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Хеши',
        icon: 'home',
        path: '/hash',
        active: true
      },
    ]
  },
  {
    adminLevel: 1,
    label: 'Функции',
    icon: UilCube,
    pages: [
      {
        adminLevel: 1,
        name: 'Проверка на новичка',
        icon: 'user',
        path: '/functions/newbie',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Полиция',
        icon: 'user',
        path: '/functions/police',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Блокировки',
        icon: 'user',
        path: '/functions/ban',
        active: true
      },
    ]
  },
  {
    adminLevel: 1,
    label: 'Статистика',
    icon: UilCube,
    pages: [
      {
        adminLevel: 1,
        name: 'Золото',
        icon: 'user',
        path: '/stats/nas',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Шоп',
        icon: 'user',
        path: '/stats/cash',
        active: true
      },
      {
        adminLevel: 2,
        name: 'Игровой магазин',
        icon: 'user',
        path: '/stats/cashshop',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Античит',
        icon: 'user',
        path: '/stats/anticheat',
        active: true
      },
    ]
  },
  {
    adminLevel: 1,
    label: 'Разное',
    icon: UilCube,
    pages: [
      {
        adminLevel: 1,
        name: 'Предметы',
        icon: 'user',
        path: '/other/items',
        active: true
      },
      {
        adminLevel: 1,
        name: 'Игровой магазин',
        icon: 'user',
        path: '/other/catalog',
        active: true
      },
    ]
  },
];
